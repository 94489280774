import {gql} from '@apollo/client';

export const ARTICLES_LIST = gql`
    query articleCategory($cat: String, $limit: Int, $offset: Int) {
        articleFindAll(category: $cat, limit: $limit, offset: $offset) {
            name
            img
            alias
            prologue
            issueDate
        } 
        articleFindAllCount(category: $cat)
    }
`;

export const ARTICLE = gql`
    query article($url: String) {
        articleFindOneByUrl(url: $url) {
            name
            img
            alias
            text
            prologue
            issueDate
            files {
                filename
                type
            }
        } 
    }
`;
 